<template>
    <div >
      <b-row>
        <b-col>
          <BranchesTableWidget
            :title="'Branches'"
          ></BranchesTableWidget>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import BranchesTableWidget from "@/view/pages/business/components/BranchesTableWidget.vue";

  import { mapGetters } from "vuex";
  
  export default {
    name: "Branches",
    computed: {
      ...mapGetters(["currentUser" , "getDate"])
    },
    components: {
        BranchesTableWidget
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Branches" },
        { title: "All Branches" }
      ]);
  
      // look for current user
      if (this.currentUser.token !== "") {
        // call method to get orders
        // this.fetchOrders();
      }
    },
  
    data() {
      return {
        form: {
          branches: ""
        }
      };
    },
    methods: {
      // fetchOrders() {
  
      //   this.$store
      //     .dispatch(BUSINESS_USERS)
      //     // go to which page after successfully login
      //     .then(resp => {
      //       this.form.users = resp.data;
      //       // this.$router.push({ name: "dashboard" })
      //     })
      //     .catch(error => {
      //       console.log(error);
      //     });
      // }
    }
  };
  </script>
  