<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">{{ title }} ({{ totalbranches }})</span>
      </h3>
    </div>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
      </v-col>
      <v-col cols="12" md="3">

      </v-col>
      <v-col cols="12" md="4">

      </v-col>
      <v-col cols="12" md="2">
        <v-btn @click="addNew" class="ma-2 bg-primary text-light" outlined>
          <v-icon left>flaticon2-add-1</v-icon>Add New
        </v-btn>

      </v-col>
    </v-row>
    <div class="card-body pt-0 pb-3">
      <v-data-table :headers="headers" :items="branches" :items-per-page="limit" :page.sync="currentPage"
        :server-items-length="totalbranches" class="elevation-1" @update:page="loadBranches">
        <!-- Desktop View -->
        <template v-slot:body="{ items }" v-if="!isMobileView">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td v-for="header in headers" :key="header.value" v-if="header.value !== 'actions'">
                {{ item[header.value] }}
              </td>
              <td>
                <span class="d-flex">
                  <v-icon medium class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
                  <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
                </span>
              </td>
            </tr>

          </tbody>
        </template>

        <!-- Mobile View -->
        <template v-slot:item="{ item }" v-else>
          <v-row class="mb-2" no-gutters>
            <v-col cols="12" class="d-flex flex-column border-bottom py-2">
              <v-row v-for="header in headers" :key="header.value">
                <v-col cols="6" class="font-weight-bold">{{ header.text }}:</v-col>
                <v-col cols="6">{{ item[header.value] }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-icon medium class="mr-2" @click="edit(item.id)" sixz>mdi-pencil</v-icon>
                  <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer.page-text>
          {{ `Displaying ${startItem} - ${endItem} of ${totalbranches} items` }}
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" @input="loadBranches"></v-pagination>
      <!-- Pagination Component -->
      <!-- <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" @input="loadBranches"></v-pagination> -->
      <b-modal id="branches-modal" size="lg" ref="branches-modal" :title="modalTitle" hide-footer>
        <AddBranch :id="currentId" @onClickCancel="hideModal" @success="formSuccess" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import AddBranch from "@/view/pages/business/branches/add.vue";
import Swal from "sweetalert2";
export default {
  name: "BranchesTableWidget",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    AddBranch
  },
  data() {
    return {
      isMobileView: window.innerWidth <= 600,
      branches: [],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalItems: 0, // Set a default value for total items
      currentPage: 1,
      totalPages: 0,
      totalbranches: 0,
      currentId: "",
      limit: 5,
      modalTitle:"Add Branch",
      loading: false,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Phone', value: 'phone', sortable: false },
        { text: 'City', value: 'city', sortable: false },
        { text: 'Country', value: 'country_name', sortable: false },
        { text: 'Total products', value: 'products', sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };

  },
  computed: {
    ...mapGetters(["currentUser"]),
    startItem() {
      return (this.currentPage - 1) * this.limit + 1;
    },
    endItem() {
      return Math.min(this.currentPage * this.limit, this.totalCategories);
    }
  },
  mounted() {
    if (this.currentUser.token !== "") {
      this.loadBranches();
    }
  },
  methods: {
    loadBranches() {
      this.loading = true;
      const url = `business/branches?page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

      ApiService.get(url).then(({ data }) => {
        this.branches = data.data;
        this.totalbranches = data.total;
        this.totalPages = Math.ceil(this.totalbranches / this.limit);
      });
    },


    // clearFilters() {
    //   this.businessName = "";
    //   this.options.page = 1;
    //   this.loadBranches();
    // },
    edit(id) {
      this.currentId = id;
      this.modalTitle='Edit Branch';
      this.$refs["branches-modal"].show();
    },
    hideModal() {
      this.$refs["branches-modal"].hide();
    },
    addNew() {
      this.currentId = "";
      this.modalTitle='Add Branch';
      this.$refs["branches-modal"].show();
    },
    formSuccess() {
      this.hideModal();
      this.loadBranches();
      Swal.fire({
        title: "",
        text: "The branch has been successfully added!",
        icon: "success"
      });

    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete(`business/branches/${id}`).then(
            ({ data }) => {
              this.loadBranches();
              Swal.fire(
                "Deleted!",
                "Your branch has been deleted.",
                "success"
              );
            }
          );
        }
      });
    },
    handleOptionsUpdate(options) {
      this.options.page = options.page;
      this.options.itemsPerPage = options.itemsPerPage;
      this.loadBranches();
    }

  }
};
</script>
