<template>
  <div>
    <form class="form" novalidate="novalidate" id="branch_form">
      <div class="form-group">
        <label>Name</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.name"
          placeholder="Branch name" name="name" ref="name" />
      </div>
      <div class="form-group">
        <label>Phone</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.phone"
          placeholder="Phone Number" name="phone" ref="phone" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.orders_email"
          placeholder="Email" name="email" ref="email" />
      </div>
      <!-- <div class="form-group">
        <label>Address</label>
        <input id="address-autocomplete" type="text" class="form-control form-control-solid form-control-lg"
          v-model="form.address" placeholder="Address" name="address" ref="address"  />
      </div> -->
      <div class="form-group">
        <label>Address</label>
        &nbsp;&nbsp;
        <GmapAutocomplete style="width:100%" class="form-control form-control-solid form-control-lg"
          name="business_address" placeholder="Business Address" @place_changed="setPlace" @focus="showAutocomplete"
          @blur="hideAutocomplete" />

        <span class="form-text text-muted">Please enter your Business Address.</span>

      </div>
      <div class="form-group">
        <label>City</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.city"
          placeholder="City" name="city" ref="city" />
      </div>
      <div class="form-group">
        <label>State</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.state"
          placeholder="State" name="state" ref="state" />
      </div>
      <div class="form-group">
        <label>Postal Code</label>
        <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.postal_code"
          placeholder="Postal Code" name="postal_code" ref="postal_code" />
      </div>
      <div class="form-group">
        <label>Country</label>
        <select class="form-control form-control-solid form-control-lg" v-model="form.country_id">
          <option value="">Select Country</option>
          <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
        </select>
      </div>
      <div v-if="!id">
        <v-row class="my-2">
          <v-col cols="12" md="4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="form.import_products" />
              <label class="form-check-label">Import Business Products</label>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="form.import_settings" />
              <label class="form-check-label">Import Business Settings</label>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="form.import_timing" />
              <label class="form-check-label">Import Business Timing</label>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="row justify-content-end">
        <button ref="branch_form_submit" class="btn btn-primary mt-3 mr-4">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import { loadGmapApi } from "vue2-google-maps";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";


export default {
  name: "BranchForm",
  props: ["id"],
  data() {
    return {
      currentPlace: null,
      nameImg: false,
      phoneImg: false,
      addressImg: false,
      form: {
        name: "",
        phone: "",
        orders_email: "",
        address: "",
        city: "",
        state: "",
        lat: 0.0,
        lng: 0.0,
        postal_code: "",
        country_id: "",
        import_products: false,
        import_settings: false,
        import_timing: false,
      },
      countries: [],
      autocomplete: null,
      errors: {}
    };
  },
  beforeMount() {
    ApiService.get("business/settings/get-general-settings")
      .then(({ data }) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].key == "google_map_key") {
            loadGmapApi({
              key: data[i].value,
              libraries: "places",
              // loading: "async"  // Add this parameter
            });
            break;
          }
        }
      })
      .catch(({ response }) => { });
  },
  mounted() {
    this.geolocate();
    const branch_form = KTUtil.getById("branch_form");
    this.fv = formValidation(branch_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Branch name is required",
            },
          },
        },
        phone: {
          validators: {
            notEmpty: {
              message: "Phone number is required",
            },
          },
        },
        orders_email: {
          validators: {
            notEmpty: {
              message: "Email  is required",
            },
          },
        },
        address: {
          validators: {
            notEmpty: {
              message: "Address is required",
            },
          },
        },
        country_id: {
          validators: {
            notEmpty: {
              message: "Country number is required",
            },
          },
        },
        postal_code: {
          validators: {
            notEmpty: {
              message: "Postal Code is required",
            },
          },
        },
        state: {
          validators: {
            notEmpty: {
              message: "State is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      const submitButton = this.$refs["branch_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let url = "business/branches";

      if (this.id) {
        url = "business/branches/" + this.id;
        form._method = "PUT";
      }

      ApiService.post(url, form)
        .then(({ data }) => {
          let currentCount = parseInt(sessionStorage.getItem("BRANCHES_COUNT")) || 0;
          sessionStorage.setItem("BRANCHES_COUNT", currentCount + 1);
          this.$emit("success");
        })
        .catch(({ response }) => {
          console.error("Error response:", response.status); // Log the response for debugging

          if (response && response.status === 422 && response.data && response.data.message) {
            // Assign the nested error message
            debugger
            this.errors.sort = response.data.message;
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
          } else {
            // Default error handling for other cases
            Swal.fire({
              title: "",
              text: "An error occurred.",
              icon: "error",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
          }
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.getCountries();
    if (this.id) this.get(this.id);

    // this.initAutocomplete();
  },
  methods: {
    getCountries() {
      ApiService.get("get-countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    get(id) {
      ApiService.get(`business/branches/${id}`).then(({ data }) => {
        this.form = { ...data.data };
      });
    },
    toggleAddress() {
      this.addressImg = !this.addressImg
    },
    setPlace(place) {
      if (!place || !place.geometry) {
        console.error("Invalid Place Data:", place);
        return;
      }

      this.currentPlace = place;
      this.form.lat = place.geometry.location.lat();
      this.form.lng = place.geometry.location.lng();
      this.form.address = place.formatted_address;
      this.form.postal_code = this.fetchPostalCode(place.address_components);

      // Extract City, State, and Country
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          this.form.city = component.long_name; // City
        }
        if (component.types.includes("administrative_area_level_1")) {
          this.form.state = component.long_name; // State
        }
        if (component.types.includes("country")) {
          this.form.country_id = component.short_name; // Country Code (e.g., US, CA)
        }
      });
    },

    showAutocomplete() {
      console.log("autocomplete")
      let pacContainer = document.querySelector(".pac-container");
      if (pacContainer) {
        pacContainer.style.display = "block !important";
        pacContainer.style.zIndex = "100000";
        pacContainer.style.visibility = "visible";
      }
    },

    hideAutocomplete() {
      setTimeout(() => {
        let pacContainer = document.querySelector(".pac-container");
        if (pacContainer) {
          pacContainer.style.display = "none !important";
        }
      }, 200); // Delay to allow selection before hiding
    },
    geolocate: function () {
      console.log("geolocate");
      navigator.geolocation.getCurrentPosition(position => {
        this.form.lat = position.coords.latitude;
        this.form.lng = position.coords.longitude;
      });
    },
    fetchPostalCode(addressComponents) {
      let postal = "";
      addressComponents.forEach((d) => {
        if (d.types.includes("postal_code")) {
          postal = d.long_name;
        }
      });
      return postal;
    },

    hide() {
      this.$emit("onClickCancel");
    },
  },
};
</script>
